// import React, { Fragment } from "react";
import React, { Fragment, useState, useEffect } from "react";
import loadable from "@loadable/component";

import LatestPodcasts from '@components/modules/Podcasts';
import GoogleCta from '@components/modules/GoogleCta';
import Awards from '@components/modules/Awards';
import AboutPeople from '@components/modules/AboutPeople';
import BoxCta from '@components/modules/BoxCta';
import PropertySlider from '@components/modules/PropertySlider';
import IconBox from '@components/modules/IconBox';
import SearchCta from '@components/modules/SearchCta';
import Faq from '@components/modules/Faq';
import ReviewGrid from '@components/ReviewGridModule/ReviewSlider';
import OtherNewsHome from "./AllNews/otherNewsHome";

// const ModuleSwitch = ({ module, teampage, projectpage, CountryFlagStatus, Email, area, area_guide,  }) => {

  
//     switch(module.moduleType) {
//         case 'IconBox':
//             return(
//                 <IconBox {...module} CountryFlagStatus={CountryFlagStatus} />
//             )
//         case 'PropertySlider':
//             return(
//                 <PropertySlider {...module} teampage={teampage} CountryFlagStatus={CountryFlagStatus} projectpage={projectpage} area={area} Email={Email} area_guide={area_guide} />
//             )
//         case 'BoxCta':
//             return(
//                 <BoxCta {...module} CountryFlagStatus={CountryFlagStatus} />
//             )
//         case 'AboutPeople':
//             return(
//                 <AboutPeople {...module} />
//             )
//         case 'Awards':
//             return(
//                 <Awards {...module} />
//             )
//         case 'GoogleCta':
//             return(
//                 <GoogleCta {...module} />
//             )
//         case 'LatestPodcasts':
//             return(
//                 <LatestPodcasts {...module} />
//             )
        
//         default:
//             return null;
//     }
// }

const ModuleRender = ({
    moduleData,
    teampage,
    projectpage,
    Email,
    area,
    CountryFlagStatus,
    area_guide,
}) => {

    const [renderComponent, setRenderComponent] = useState(true);

    // useEffect(() => {
    //   window.addEventListener("mousemove", () => {
    //     if (renderComponent === false) {
    //       setRenderComponent(true)
    //     }
    //     })
    //     window.addEventListener("touchmove", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    //     window.addEventListener("keypress", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    // }, [])
  
    return (
        <>
            {moduleData?.map((module) => {
                return (
                    <Fragment key={module.id}>
                      
                        {/* <ModuleSwitch
                            module={module}
                            sidebarData={sidebarData}
                            videos={videos}
                            currentCountryName={currentCountryName}
                            contact_label_list={contact_label_list}
                            Email={Email}
                            path={path}
                            teampage={teampage}
                            area={area}
                            video_url={video_url}
                            projectpage={projectpage}
                            title={title}
                            isPage={isPage}
                            slug={slug}
                            area_guide={area_guide}
                            reviews={reviews}
                            CountryFlagStatus={CountryFlagStatus}
                             loading={loading}
                             error={error}
                        /> */}
                        { module.moduleType == "SearchCta"  && renderComponent && <SearchCta {...module} /> }

                        { module.moduleType == "IconBox"  && renderComponent && <IconBox {...module} CountryFlagStatus={CountryFlagStatus} /> }
        
                        { module.moduleType == "PropertySlider" && renderComponent && <PropertySlider {...module} teampage={teampage} CountryFlagStatus={CountryFlagStatus} projectpage={projectpage} area={area} Email={Email} area_guide={area_guide} />}
        
                        { module.moduleType == "BoxCta" && renderComponent && <BoxCta {...module} CountryFlagStatus={CountryFlagStatus} /> }

                        { module.moduleType == "OtherNewsHome" && renderComponent && <OtherNewsHome {...module}  moduleClass = "section-grey" sectionSpacing= "py-48 py-md-64 py-lg-120"/> }
        
                        { module.moduleType == "AboutPeople"  && renderComponent && <AboutPeople {...module} /> }
            
                        { module.moduleType == "Awards" && renderComponent && <Awards {...module} /> }
        
                        { module.moduleType == "GoogleCta" && renderComponent && <GoogleCta {...module} /> }

                        {module.moduleType == "faq" && renderComponent && <Faq {...module} />}

                        {module.moduleType == "CustomerReview" && renderComponent &&  <ReviewGrid {...module} />}
        
                        { module.moduleType == "LatestPodcasts" && renderComponent && <LatestPodcasts {...module} /> }
     
                    </Fragment>
                )
            })}
        </>
    )
}

export default ModuleRender;