import React from "react";
import Stack from "react-bootstrap/Stack";
import Col from "react-bootstrap/Col";
import { StarberryIcons } from "@components/icons/index";
import ModuleText from '@components/modules/Text';
import moment from 'moment';

import './reviewCard.scss';

const ReviewCard = ({ review, className, truncateText, custom, CountryFlagStatus }) => {
    const {
        Text,
        Person,
        Date
    } = review

    const truncate = (str) => {
        const stripedHtml = str.replace(/<[^>]+>/g, ''); // Remove HTML tags from code of any
        return stripedHtml.length > 10 ? stripedHtml.substring(0, 160) + "..." : stripedHtml;
    }
    return(
        <Col
            className={`reviews-card ${CountryFlagStatus ? "px-24 py-32 px-lg-40 py-lg-40" : "px-24 py-32 p-md-48"}${className ? ' ' + className : ''}`}
            // variants={delayItemsFromBottom}
            custom={custom}
        >
            <Stack
                className="gap-24 gap-md-32"
            >
                <StarberryIcons iconName="quoteIcon" className="reviews-card-icon" />
                {Text &&
                    <ModuleText
                        text={truncateText ? truncate(Text) : Text}
                        className="reviews-card-text"
                    />
                }
                <Stack className="gap-4">
                    {Person &&
                        <span className="reviews-card-name">{Person}</span>
                    }
                    {Date &&
                        <div className="reviews-card-date">
                            {moment(Date).format("D MMM YYYY")}
                        </div>
                    }
                </Stack>
            </Stack>
        </Col>
    )
}

export default ReviewCard