import React from "react";
// import gql from "graphql-tag"
// import { useQuery } from '@apollo/client';
import ModuleSection from '@components/modules/moduleWrapper';
import { SwiperSlide } from 'swiper/react';
import Carousel from '@components/carousel';
import NewsCard from '@components/modules/AllNews/newsCard';
import ModuleText from '@components/modules/Text';
import ModuleTitle from '@components/modules/Title';
import Loader from '@components/loader';
import { useStaticQuery, graphql } from "gatsby"
import { fadeIn } from '@components/utils/animations';
import ButtonLink from "@components/ButtonLink"

import './otherNews.scss';

const OtherNewsSlider = ({ NewsTitle, NewsDesc , sectionSpacing , moduleClass}) => {
    // const { loading, error, data } = useQuery(GET_ALL_NEWS);
    const data = useStaticQuery(graphql`
    query {
      glstrapi {
        allNews(publicationState: LIVE, sort: "Date:DESC", limit: 3) {
            id
            Slug
            Date
            tag
            imagetransforms
            BannerImage {
                ... on GLSTRAPI_ComponentModulesBannerImage {
                    id
                    VideoUrl
                    ItemText
                    ItemTitle
                    id
                    Image {
                        alternativeText
                        url
                    }
                }
            }
        }
      }
    }
    `)
    const sliderConfig = {
        slidesPerView: 1,
        spaceBetween: 32,
        autoplay: true,
        breakpoints: {
            // when window width is >= 768px
            768: {
              slidesPerView: 2,
              spaceBetween: 16
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 32
            }
        }
    }

    return(
        <Loader loading={false} error={false}>
            <ModuleSection
                //removeContainer={moduleData?.removeContainer ? moduleData.removeContainer : false}
                sectionSpacing={sectionSpacing}
                sectionClass={`module news-slider home ${moduleClass ? moduleClass : ''}`}
            >
                {NewsTitle &&
                    <div className="mb-16 mb-md-24 mb-lg-32 module__title" variants={fadeIn}>
                        <ModuleTitle
                            title={NewsTitle}
                            className="mb-16 mb-md-24 mb-lg-32 module__title"
                        />
                    </div>
                }
                {NewsDesc &&
                    <ModuleText
                        text={NewsDesc}
                        className="module__text d-block mb-40 mb-lg-56"
                    />
                }
                <Carousel className="news-carousel" {...sliderConfig}>
                    {data?.glstrapi?.allNews?.map((news, i) => {
                            return(
                                <SwiperSlide key={news.id}>
                                    <NewsCard news={news} className="news-slider__item" custom={i} />
                                </SwiperSlide>
                            )
                    })}
                </Carousel>
                <div className="other-news-end-cta">
                  <ButtonLink manualLink="/media/uae-property-blog/" btnClass="btn btn-primary" label="View More"/>
                </div>
            </ModuleSection>
        </Loader>
    )
}

export default OtherNewsSlider