import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap"
import { Link } from 'gatsby';
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';
import { StarberryIcons } from "@components/icons/index";
import "./popup.scss"

const Popup = ({ show, onClose, popupData }) => {
    if (!popupData) return null;

    const ctaLink = popupData.CTA_Link || (popupData.Use_Default_CTA_Link ? popupData.Default_CTA_Link : "");
    const isInternalLink = ctaLink && (ctaLink.includes("exclusive-links.com") || ctaLink.includes("exclusivelinks-dev.q.starberry.com"));

    return (
        <Modal
            show={show}
            onHide={onClose}
            dialogClassName="popup-modal"
            backdrop="static"
            aria-labelledby="popup-modal-title"
            keyboard={false}
            centered
            className="popup-modal-wrapper"
        >
            <Modal.Header>
            {popupData?.Image?.url_sharp ?
                    <GatsbyImage
                        image={getImage(popupData?.Image?.url_sharp)}
                        alt={popupData?.Image?.alternativeText ? popupData?.Image?.alternativeText : ""}
                        className="popup-image"
                    />
                :
                    <StaticImage
                        src="../images/no-image.png"
                        quality={100}
                        width={800}
                        height={1200}
                        formats={["auto", "webp"]}
                        alt=""
                        className="popup-image"
                    />
                }
            </Modal.Header>
            <Modal.Body closeButton className="popup-content">
                    <Button variant="none" className="closeIcon" onClick={onClose} aria-label="Close">
                        <StarberryIcons iconName="CloseIconBlue" className="closeIconBlue bi" />
                    </Button>
                    {popupData.Heading &&
                        <ModuleTitle
                            as="h6"
                            title={popupData.Heading}
                            className="module__title mb-16"
                        />
                    }
                     {popupData.Content &&
                        <ModuleText
                            text={popupData.Content}
                            className="popup-content module__text mb-16 mb-md-24"
                        />
                    }
                    {ctaLink && (
                        isInternalLink ? (
                            <Link to={ctaLink} className="btn btn-primary" onClick={onClose}>
                                {popupData?.CTA_Label || 'Contact Us'}
                            </Link>
                        ) : (
                            <a href={ctaLink} className="btn btn-primary" target="_blank" onClick={onClose}>
                                {popupData?.CTA_Label || 'Contact Us'}
                            </a>
                        )
                    )}
            </Modal.Body>
        </Modal>
    );
};

export default Popup;
