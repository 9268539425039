import React, { useState, useEffect } from "react";
import "@src/scss/App.scss";
import Seo from "@components/seo";
import Layout from "@components/layout";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import ModuleRender from "@components/modules/moduleRenderHome";
import BannerRender from "@components/modules/Banner/BannerRender";
// import gql from "graphql-tag"
// import { useQuery } from "@apollo/client"
import { useLocation } from "@reach/router"
import Popup from "../components/Popup/popup";
import Blue_Trans from "../images/Blue_Trans.jpg";

const Pages = ({
    // pageContext,
    data,
    // location
}) => {

// const GET_REVIEW_TEAM = gql`
//   query GetReviewDetail($country: String!){
//       reviews(publicationState: LIVE, sort: "Date:DESC", where: { Country: $country }) {
//           id
//           Date
//           Person
//           Text
//           Country
//       }
//   }
//   `;
    var {pathname} = useLocation()
    var path = pathname.replaceAll("/", "");
    var pathVal = path.toUpperCase();

    var enbaleRightAlign = pathVal ? pathVal === "UAE" ? true : false : false
    var enbaleFrenchPage = pathVal ? pathVal === "FR" ? true : false : false

    var currentCountryName = pathVal.length > 0 ?  pathVal === "RU" && "Russian" || pathVal === "CN" && "China" || pathVal === "FR" && "France" || pathVal === "UAE" && "United Arab Emirates" : "" ;
    
    // const { loading: loadingR, error : errorR, data: dataR } = useQuery(GET_REVIEW_TEAM,{
    //         variables: { country: pathVal}
    //     } );
    const { glstrapi: { page, popularSearch, popup  } } = data
    const [showPopup, setShowPopup] = useState(true);

    useEffect(() => {
        // Check session storage for popup visit status
        const hasVisited = sessionStorage.getItem('hasVisited');
        if (hasVisited) {
            setShowPopup(false);
        }
    }, []);

    const handlePopupClose = () => {
        setShowPopup(false);
        sessionStorage.setItem('hasVisited', 'true');
    };
    const pageurl = typeof window !== 'undefined' ? window.location.pathname : page?.Slug
    const breadcrumbs = [
        {...page?.Parent ? { label: page?.Parent?.Pagename, url: `/${page?.Parent?.Slug}`} : ""},
        {...page?.Parent2 ? { label: page?.Parent2?.Pagename, url: `/${page?.Parent?.Slug}/${page?.Parent2?.Slug}`} : ""},
        {...page?.Parent3 ? { label: page?.Parent3?.Pagename, url: `/${page?.Parent?.Slug}/${page?.Parent2?.Slug}/${page?.Parent3?.Slug}`} : ""},
        { label: page?.Pagename, url: `${pageurl}` }
    ]
    let bodyClass,
        headerClass;
    if (page?.HeaderTemplate === "Terms") {
        bodyClass = "noBannerImage list-property-page";
        headerClass = "header-dark";
    }
    if(typeof window !== 'undefined'){
        window.sessionStorage.setItem('Page', window.location.pathname)
    
    }

    const multiCountryPageCheck = popularSearch.Country_Menu.map((item) => item.label).includes(path);
    const menu_list = page.BannerImage.find(item => item.__typename === "GLSTRAPI_ComponentModulesMenuList")
    const contact_label_list = page.BannerImage.find(item => item.__typename === "GLSTRAPI_ComponentModulesContactFormFields")
    const footer_content = page.BannerImage.find(item => item.__typename === "GLSTRAPI_ComponentModulesFooterContent")
    const CountryFlagStatus = menu_list?.Why_Exclusive_Links ? true : false;
    return (
        <div className="home-page">
        <Layout enbaleRightAlign={enbaleRightAlign} enbaleFrenchPage={enbaleFrenchPage} popularSearch={page?.Popular_Search} bodyClass={bodyClass} headerClass={headerClass} menu_list={menu_list} footer_content={footer_content} >
        <Helmet>
            { page?.Pagename === "Home" &&
                <script type="application/ld+json">{`{
         
                    "@context": "http://schema.org",
                    "@type": "Product",
                    "name" : "${page?.Metadata?.title}",
                    "description" : "${page?.Metadata?.description}",
                    "brand" : {
                        "@type" : "Brand",
                        "name" : "Exclusive Links",
                        "image" : "${Blue_Trans}" 
                    },
                    "aggregateRating": {
                            "@type": "AggregateRating",
                            "ratingValue": "4.6",
                            "reviewCount": "323",
                            "bestRating": "5"
                    }
    }`}
      </script>
            }
            
            </Helmet>
            <Helmet>
            { page?.Pagename === "Home" &&
                <script type="application/ld+json">{`{
         
                    "@context": "https://schema.org",
                    "@type": "VideoObject",
                    "name": "Best Real Estate Company in Dubai | Exclusive Links Real Estate Brokers",
                    "description": "Start your property journey with Exclusive Links.  We have been established since 2005 offering the complete property experience including Sales, Leasing, Off Plan, Property Management, Vacation Homes and our Elite Collection. Call +971 4 399 4937 or email property@exclusie-links.com to speak with a member of our team today Exclusive Links Real Estate Brokers is one of the UAEs leading brokerage firms, offering a complete property experience. Exclusive Links Real Estate will offer to their customers a full complement of property activities including Sales, Leasing, and Dubai Property Management. Since its establishment in 2005 the company has grown in strength and reputation and has maximised the business potential to the benefit of our valued local and international client base.",
                    "thumbnailUrl": "https://img.youtube.com/vi/RwzaG-uoVzs/default.jpg",
                    "uploadDate": "2022-08-23",
                    "duration": "PT1M54S",
                    "contentUrl": "https://www.youtube.com/watch?v=RwzaG-uoVzs",
                    "potentialAction": {
                        "@type": "SeekToAction",
                        "target": "https://www.exclusive-links.com/={seek_to_second_number}",
                        "startOffset-input": "required name=seek_to_second_number"
                    }
    }`}
      </script>
            }
            
            </Helmet>
            <Seo
                title={page?.Metadata?.title ? page.Metadata.title : page.Title}
                description={page?.Metadata?.description ? page.Metadata.description : null}
                // image={page?.Metadata?.image?.url ? page.Metadata.image.url : null}
                image={page?.Metadata?.image?.url_sharp?.childImageSharp ? page.Metadata.image.url_sharp.childImageSharp.resize : null}
            />
            <BannerRender
                page={page}
                CountryFlagStatus={multiCountryPageCheck}
                breadcrumbs={breadcrumbs}
                path={path}
            />
           
            {page?.BannerImage &&
                <ModuleRender
                    CountryFlagStatus={CountryFlagStatus}
                    moduleData={page?.BannerImage}
                     // reviews={dataR?.reviews}
                    currentCountryName={currentCountryName}
                    path={path}
                    contact_label_list={contact_label_list}
                />
            }
            {popup && popup?.ShowPopup && (                
                <Popup
                    show={showPopup}
                    onClose={handlePopupClose}
                    popupData={popup}
                />
            )}
        </Layout>
        </div>
    )
}

export default Pages

export const query = graphql`
query defaultQueryHome($id: ID!) {
    glstrapi {
        popularSearch {
            __typename
            Country_Menu {
              Show
              label
              value
            }
        }
        popup {
            id
            Heading
            Content
            CTA_Label
            CTA_Link
            Use_Default_CTA_Link
            Default_CTA_Link
            ShowPopup
            Image {
                alternativeText
                url
                url_sharp {
                childImageSharp {
                    gatsbyImageData(width: 800, height: 400, quality: 100)
                }
                }
            }
        }
        page(id: $id, publicationState: LIVE) {
            id
            Pagename
            Title
            Slug
            PageTemplate
            HeaderTemplate
            Popular_Search
            Parent {
                Pagename
                Slug
            }
            Parent2 {
                Pagename
                Slug
            }
            Parent3 {
                Pagename
                Slug
            }
            imagetransforms
            BannerImage {
                ... on GLSTRAPI_ComponentModulesBannerCarousel {
                    ...ImageBannerCarouselFragment
                }
                ... on GLSTRAPI_ComponentModulesTeamModule {
                    ...TeamModuleFragment
                }
                ... on GLSTRAPI_ComponentModulesAboutPoints {
                    ...AboutPointsModuleFragment
                }
                ... on GLSTRAPI_ComponentModulesMenuList {
                    __typename
                    id
                    Contact
                    List_Your_Property
                    Services
                    Why_Dubai
                    Why_Exclusive_Links
                }
               
                ... on GLSTRAPI_ComponentModulesFooterContent {
                    id
                    __typename
                    Sitemap
                    contact_us
                    content
                    copyright
                    email_us
                    help_you_heading
                    News_Letter
                    privacy_policy
                    rights
                    site_by
                    trending_searches
                    terms_conditions
                    whatsapp_us
                    services_menu {
                      buy
                      off_plan
                      property_management
                      luxury_property
                      rent
                      sell
                      services
                      vacation_homes
                    }
                    others {
                      about
                      contact
                      media
                      meet_the_team
                      others
                      recuirement
                      reviews
                    }
                  }
               
                ... on GLSTRAPI_ComponentModulesBannerImage {
                    ...ImageBannerFragment
                    ...ImgBtnFragment
                    ...ImgBtnNormalFragment
                    ...TermsBannerFragment
                }
                ... on GLSTRAPI_ComponentModulesSearchCta {
                    ...PropertySearchFragment
                }
                ... on GLSTRAPI_ComponentModulesIconBox {
                    ...IconBoxFragment
                }
                ... on GLSTRAPI_ComponentModulesPropertySlider {
                    ...PropertySliderFragment
                }
                ... on GLSTRAPI_ComponentModulesBoxCta {
                    ...BoxCtaFragment
                }
                ... on GLSTRAPI_ComponentModulesFaq {
                    ...FaqFragment
                  }
                  ... on GLSTRAPI_ComponentModulesCustomerReviewModule {
                    id
                    moduleType
                    ReviewSectionTitle
                    ReviewSectionDesc
                  }
                  ... on GLSTRAPI_ComponentModulesOtherNewsHome {
                    id
                    NewsDesc
                    NewsTitle
                    moduleType
                  }

                ... on GLSTRAPI_ComponentModulesAboutPeople {
                    ...AboutPeopleFragment
                }
                ... on GLSTRAPI_ComponentModulesAwards {
                    ...AwardsSliderFragment
                }
                ... on GLSTRAPI_ComponentModulesGoogleCta {
                    ...GoogleCtaFragment
                }
                ... on GLSTRAPI_ComponentModulesLatestPodcasts {
                    ...LatestPodcastsFragment
                }
               
            }
           
            Metadata {
                title
                description
                image {
                    url
                    url_sharp {
                        childImageSharp {
                            resize(width: 1200) {
                                src
                                height
                                width
                            }
                        }
                    }
                }
            }
        }
    }
}
`